import React, { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/all";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import abbreviateNumber from "@helpers/chart-data";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";
import {
  Canvas,
  ChartWrapper,
  CanvasWrapper,
  Header,
  Description,
  Subheading,
  Above,
} from "./bar-chart.styled";

Chart.register(ChartDataLabels);

const BarChart = ({
  width = "100%",
  height = "400px",
  isHorizontal = true,
  chartData: {
    id,
    header,
    subheading,
    barColor = Colors.beachTeal,
    labels = [],
    data = [],
    description,
    percentage = false,
  },
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // mobile vs desktop config
    const scalesConfig = isHorizontal
      ? {
          y: {
            grid: {
              offsetGridLines: true,
              display: false,
              tickLength: 30,
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
            },
          },

          x: {
            display: false,

            ticks: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
            },
          },
        }
      : {
          y: {
            display: false,

            ticks: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
            },
          },

          x: {
            grid: {
              offsetGridLines: true,
              display: false,
              tickLength: 30,
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
            },
          },
        };

    // main config
    const chartConfig = {
      type: "bar",

      data: {
        labels,
        datasets: [
          {
            label: "",
            barThickness: 36,
            backgroundColor: barColor,
            data,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: isHorizontal ? "y" : "x",

        plugins: {
          datalabels: {
            clip: false,
            anchor: "start",
            offset: 15,
            formatter: value => {
              if (percentage === true) {
                return `${value}%`;
              } else {
                return abbreviateNumber(value);
              }
            },
            clamp: true,
            align: "end",
            color: Colors.white,
            font: {
              family: Fonts.ProximaNovaFont,
              weight: "bold",
            },
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },

        animation: {
          duration: 1200,
        },

        scales: scalesConfig,
      },
    };

    // animation
    const canvasEl = canvasRef.current;
    const canvasContext = canvasEl.getContext("2d");
    let chart = new Chart(canvasContext, chartConfig);
    const scrollTriggerInstance = ScrollTrigger.create({
      trigger: canvasEl,
      start: "top bottom",
      end: "center bottom",
      invalidateOnRefresh: true,
      onEnter: () => chart.destroy(),
      onLeave: () => (chart = new Chart(canvasContext, chartConfig)),
    });

    // destroy scroll animations on unmount
    return () => {
      chart.destroy();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <ChartWrapper>
      {header && <Header>{header}</Header>}
      {subheading && <Subheading>{subheading}</Subheading>}

      <CanvasWrapper
        className="chart-container"
        canvasWidth={width}
        canvasHeight={height}
        chartData={data}
      >
        <Canvas id={`bar-chart-${id}`} ref={canvasRef} />
      </CanvasWrapper>

      {description && (
        <Description>
          <Above color={barColor}>Above: </Above>
          {description}
        </Description>
      )}
    </ChartWrapper>
  );
};

export default BarChart;
