import styled from "styled-components";
import Section from "@components/section";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";

const StyledSection = styled(Section)`
  flex-direction: column;
`;

const SmallHeader = styled.h4`
  color: ${Colors.beachTeal};
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin: 0;
`;

const LargeHeader = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 40px;
  line-height: 1.3;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: 0;

  @media (max-width: ${Screens.lg}) {
    font-size: 28px;
  }
`;

const Info = styled.p`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  max-width: 640px;
  margin: 24px auto 74px;

  @media (max-width: ${Screens.lg}) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const QuoteWrapper = styled.div`
  border-radius: 10px;
  background: ${Colors.white};
  max-width: 978px;
  padding: 96px 80px 96px;
  margin: 0 auto;

  @media (max-width: ${Screens.md}) {
    padding: 64px 20px;
    margin: 0 20px;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
  padding: 0 96px 0 144px;

  @media (max-width: ${Screens.sm}) {
    padding: 0 14px 0 64px;
  }
`;

const Text = styled.p`
  position: relative;
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  width: 100%;
  margin: 0;

  &::before {
    font-weight: bold;
    color: ${Colors.beachTeal};
    font-size: 144px;
    height: 144px;
    line-height: 144px;
    font-family: ${Fonts.ClearfaceFont};
    display: block;
    content: "\\201C";
    position: absolute;
    right: calc(100% + 20px);
    top: 0;
  }

  @media (max-width: ${Screens.sm}) {
    font-size: 20px;
    line-height: 30px;

    &::before {
      font-size: 72px;
      height: 72px;
      line-height: 72px;
    }
  }
`;

const AuthorImg = styled.div`
  width: 100px;
  margin-right: 16px;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
`;

const AuthorInfo = styled.div`
  font-size: 18px;
  align-self: stretch;

  @media (max-width: ${Screens.sm}) {
    font-size: 16px;
  }
`;

const Attribution = styled.div`
  margin-top: 64px;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${Screens.sm}) {
    margin-top: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Author = styled.p`
  font-weight: bold;
  margin: 0 0 8px;
`;

const AuthorTitle = styled.p`
  margin: 0;
`;

export {
  SmallHeader,
  LargeHeader,
  Info,
  QuoteWrapper,
  QuoteContainer,
  Attribution,
  AuthorImg,
  AuthorInfo,
  AuthorTitle,
  Author,
  Text,
  StyledSection,
};
