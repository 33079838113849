import React, { useState, useEffect } from "react";
import { Screens } from "@constants/screens";
import ScrollIndicator from "@components/scroll-indicator";
import DesktopHome from "./home";
import MobileHome from "./mobile-home";
import { ScrollTrigger } from "gsap/all";

const Home = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false);

  // switch between mobile and desktop layouts
  useEffect(() => {
    const handleResize = () => {
      // recalculate positions
      ScrollTrigger.refresh();

      // update mobile state
      const isMobileResolution =
        window.innerWidth <= Number.parseInt(Screens.md);
      if (isMobileResolution !== isMobile) {
        setIsMobile(isMobileResolution);
      }
    };

    if (typeof window !== "undefined") {
      handleResize();
      ScrollTrigger.refresh();
      window.addEventListener("resize", handleResize);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  // scroll to top by default so that indicators are visible
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    ScrollTrigger.refresh();
  });

  return (
    <>
      <ScrollIndicator scrollIndicatorText={data.scrollIndicatorText} />
      {isMobile ? (
        <MobileHome data={data} />
      ) : (
        <DesktopHome data={data} />
      )}
    </>
  );
};

export default Home;
