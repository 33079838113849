import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import {
  SectionsWrapper,
  StyledCanvas,
} from "./mobile-introduction-showcase.styled.js";
import MobileIntroduction from "./introduction/mobile-introduction";
import MobileShowcase from "./showcase/mobile-showcase";

gsap.registerPlugin(ScrollTrigger);

const imageSequences = {
  introduction: {
    srcPrefix: "/introduction-mobile/introduction-mobile_",
    frameCount: 60,
    width: 576,
    height: 1024,
  },

  showcase: {
    sectionTransition: {
      srcPrefix: "/plunge-mobile/plunge-mobile_",
      frameCount: 20,
      width: 576,
      height: 1024,
    },

    newsletterTransition: {
      srcPrefix: "/hint-mobile/hint-mobile_",
      frameCount: 20,
      width: 480,
      height: 854,
    },

    takeaways: [
      {
        frameCount: 20,
        srcPrefix: "/inbox/inbox_",
        width: 480,
        height: 854,
      },
      {
        frameCount: 19,
        srcPrefix: "/uber/uber_",
        width: 480,
        height: 854,
      },
      {
        frameCount: 19,
        srcPrefix: "/convert/convert_",
        width: 480,
        height: 854,
      },
    ],
  },
};

const MobileIntroductionShowcase = ({ data }) => {
  const { width, height } = imageSequences.introduction;
  const canvasRef = useRef(null);
  const sectionsWrapperRef = useRef(null);
  const introductionSectionRef = useRef(null);
  const showcaseSectionRef = useRef(null);
  const introductionHeadlineRef = useRef(null);
  const introductionRefs = {
    canvasRef,
    introductionHeadlineRef,
    introductionSectionRef,
  };
  const showcaseRefs = {
    sectionsWrapperRef,
    introductionHeadlineRef,
    introductionSectionRef,
    showcaseSectionRef,
  };

  const arrangeSections = () => {
    gsap.set([introductionSectionRef.current, showcaseSectionRef.current], {
      zIndex: (index, _, targets) => targets.length - index,
    });
  };

  useEffect(() => {
    arrangeSections();
  });

  return (
    <>
      <StyledCanvas ref={canvasRef} srcWidth={width} srcHeight={height} />
      <SectionsWrapper ref={sectionsWrapperRef}>
        <MobileShowcase
          data={data.showcase}
          imageSequences={imageSequences.showcase}
          ref={showcaseRefs}
        />
        <MobileIntroduction
          data={data.introduction}
          imageSequence={imageSequences.introduction}
          ref={introductionRefs}
        />
      </SectionsWrapper>
    </>
  );
};

export default MobileIntroductionShowcase;
