import React from "react";
import GetStarted from "@modules/get-started";
import IntroductionShowcase from "@modules/introduction-showcase";
import Mission from "@modules/mission";
import EmailAdvantage from "@modules/email-advantage";
import ChartsPossibilities from "@modules/charts-possibilities";
import WhyWeDoIt from "@modules/why-we-do-it";
import Partnership from "@modules/partnership";

const DesktopHome = ({ data }) => (
  <div>
    <IntroductionShowcase data={data.introductionShowcase} />
    <Mission data={data.missionText} />
    <EmailAdvantage data={data.emailAdvantage} />
    <ChartsPossibilities data={data} />
    <WhyWeDoIt data={data.why} />
    <Partnership data={data.partnership} />
    <GetStarted />
  </div>
);

export default DesktopHome;
