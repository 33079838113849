import styled from "styled-components";
import { Screens } from "@constants/screens";
import { ClearfaceFont } from "@constants/fonts";
import * as Colors from "@constants/colors";
import PhoneSVG from "@svg-icons/phone";
import ButtonLink from "@components/button-link";
import {
  BlockDescription,
  BlockHeader,
  BlockTitle,
  Wrapper,
} from "@components/shared.styled";

const CopyBlock = styled.div`
  height: 100%;
  width: 35%;
  top: 0;
  left: 0;
  z-index: 5;

  @media (max-width: ${Screens.md}) {
    width: 80%;
    max-width: 420px;
  }
`;

const Section = styled.section`
  padding-top: 72px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background-color: ${Colors.white};
  color: ${Colors.white};
  z-index: 1;
`;

const SectionCanvasWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 36px));
  z-index: 3;
  overflow: hidden;
  height: 100vh;
  width: calc((16 / 9) * 100vh);
  background-color: ${Colors.charcoal};

  @media (min-aspect-ratio: 16/9) {
    height: calc((9 / 16) * 100vw);
    width: 100vw;
  }
`;

const StyledBlockDescription = styled(BlockDescription)`
  margin-bottom: 32px;
  color: ${Colors.charcoal};

  @media (max-width: ${Screens.md}) {
    font-size: 18px;
    line-height: 1.4;
  }
`;

const StyledBlockHeader = styled(BlockHeader)`
  font-family: ${ClearfaceFont};
  font-size: 48px;
  line-height: 1.5;
  text-align: center;
  color: ${Colors.white};
  width: 100%;
  margin: 0;
  z-index: 4;

  @media (max-width: ${Screens.md}) {
    font-size: 28px;
    margin: 0 auto;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  color: ${Colors.charcoal};
  margin-bottom: 32px;
  line-height: 1.5;
  @media (max-width: ${Screens.md}) {
    font-size: 28px;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  font-size: 16px;
  padding: 12px 24px 8px;
`;

const StyledPhoneSVG = styled(PhoneSVG)`
  height: 100%;
`;

const StyledWrapper = styled(Wrapper)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  border-radius: 24px;
  z-index: 3;

  @media (orientation: landscape) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }

  @media (orientation: portrait) and (max-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcHeight}/${srcWidth}`}) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }

  @media (orientation: portrait) and (min-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcHeight}/${srcWidth}`}) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }
`;

const TabletWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 65%;
`;

export {
  CopyBlock,
  Section,
  SectionCanvasWrapper,
  StyledBlockDescription,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledButtonLink,
  StyledPhoneSVG,
  StyledWrapper,
  StyledCanvas,
  TabletWrapper,
};
