import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { getSequenceTween } from "@helpers/animations";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  LargeHeading,
  Section,
  StyledDevicesSVG,
  StyledWrapper,
  SVGWrapper,
} from "./introduction.styled.js";

gsap.registerPlugin(ScrollTrigger);

const Introduction = React.forwardRef(
  ({ data, imageSequences }, ref) => {
    const { canvasRef, introductionSectionRef, introductionHeadlineRef } = ref;
    const { logoSequence, newsletterSequence } = imageSequences;
    const desktopImage = `${newsletterSequence.srcPrefix}${(
      newsletterSequence.frameCount - 1
    )
      .toString()
      .padStart(4, "0")}.jpg`;

    const svgRefs = {
      svgRef: useRef(null),
      tabletRef: useRef(null),
      phoneRef: useRef(null),
      desktopRef: useRef(null),
      displayRef: useRef(null),
    };
    const { displayRef, desktopRef, phoneRef, tabletRef } = svgRefs;

    const logoTimeline = () => {
      const { srcPrefix, frameCount, width, height } = logoSequence;
      const displayEl = displayRef.current;

      return (
        gsap
          .timeline()
          // toggle svg
          .set(displayEl, { opacity: 0 })

          // scroll through image sequence
          .add(
            getSequenceTween(
              {
                canvasRef,
                duration: 4,
                frameOptions: {
                  srcPrefix,
                  frameCount,
                  width,
                  height,
                },
              },
            ),
            0
          )
      );
    };

    const newsletterTimeline = () => {
      const { srcPrefix, frameCount, width, height } = newsletterSequence;
      const display = displayRef.current;
      const canvas = canvasRef.current;

      return (
        gsap
          .timeline()
          // scroll through image sequence
          .add(
            getSequenceTween(
              {
                canvasRef,
                duration: 1,
                frameOptions: {
                  srcPrefix,
                  frameCount,
                  width,
                  height,
                },
                renderFirstFrame: false,
                ease: "none",
              },
            ),
            0
          )

          // scale down canvas
          .to(
            canvas,
            {
              borderRadius: 20,
              scale() {
                const canvasHeight = canvas.getBoundingClientRect().height;
                const displayHeight = display.getBoundingClientRect().height;
                return displayHeight / canvasHeight;
              },
              duration: 1,
              ease: "circ.out",
            },
            ">-1"
          )

          // toggle canvas and svg image
          .set(display, { opacity: 1.0 })
          .set(canvas, { display: "none" })
      );
    };

    const svgTimeline = () => {
      const desktop = desktopRef.current;
      const phone = phoneRef.current;
      const tablet = tabletRef.current;

      return (
        gsap
          .timeline()
          // fade in desktop svg group
          .fromTo(
            desktop,
            { opacity: 0 },
            { opacity: 1.0, duration: 0.5, ease: "power2.out" },
            0
          )

          // shift and fade in phone svg group
          .fromTo(
            phone,
            { x: -64 },
            { x: 0, duration: 0.5, ease: "power2.out" },
            0
          )
          .fromTo(phone, { opacity: 0 }, { opacity: 1.0, duration: 0.25 }, "<")

          // shift and fade in tablet svg group
          .fromTo(
            tablet,
            { x: 64 },
            { x: 0, duration: 0.5, ease: "power2.out" },
            0
          )
          .fromTo(tablet, { opacity: 0 }, { opacity: 1.0, duration: 0.25 }, "<")
      );
    };

    const applyAnimations = () => {
      const display = displayRef.current;
      const introductionSection = introductionSectionRef.current;
      const scrollTriggerId = "introductionSequence";
      const masterTimeline = gsap.timeline();

      masterTimeline.add(logoTimeline());
      masterTimeline.add(newsletterTimeline());
      masterTimeline.add(svgTimeline(), ">-0.5");

      return {
        timeline: masterTimeline,
        scrollTriggerInstance: ScrollTrigger.create({
          id: scrollTriggerId,
          animation: masterTimeline,
          trigger: introductionSection,
          start: "top top",
          endTrigger: display,
          end: "center center",
          invalidateOnRefresh: true,
          scrub: true,
        }),
      };
    };

    useEffect(() => {
      const { timeline, scrollTriggerInstance } = applyAnimations();
      return () => {
        timeline.pause(0).kill();
        scrollTriggerInstance.kill(true);
      };
    });

    return (
      <Section colorName="charcoal" ref={introductionSectionRef}>
        <StyledWrapper>
          <SVGWrapper>
            <StyledDevicesSVG desktopImage={desktopImage} ref={svgRefs} />
          </SVGWrapper>
          <LargeHeading
            ref={introductionHeadlineRef}
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHtml(data.headline),
            }}
          />
        </StyledWrapper>
      </Section>
    );
  }
);

export default Introduction;
