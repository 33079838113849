import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import * as Colors from "@constants/colors";
import { getSequenceTween, textTween } from "@helpers/animations";
import {
  CopyBlock,
  Section,
  StyledButtonLink,
  StyledBlockDescription,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledPhoneSVG,
  StyledWrapper,
  StyledCanvas,
  TabletWrapper,
} from "./mobile-possibilities.styled.js";
import { BlockSupTitle } from "@components/shared.styled";

gsap.registerPlugin(ScrollTrigger);

const imageSequence = {
  srcPrefix: "/smoke-mobile/smoke-mobile_",
  frameCount: 49,
  width: 576,
  height: 1024,
};

const MobilePossibilities = React.forwardRef(({ data }, ref) => {
  const svgRefs = { svgRef: useRef(null), displayRef: useRef(null) };
  const { chartsSectionRef, sectionsWrapperRef } = ref;
  const canvasRef = useRef(null);
  const tabletWrapperRef = useRef(null);
  const firstHeadlineRef = useRef(null);
  const secondHeadlineRef = useRef(null);
  const copyBlockRef = useRef(null);

  const applyAnimations = () => {
    const scrollTriggerId = "possibilitiesAnimation";
    const sectionsWrapper = sectionsWrapperRef.current;
    const chartsSection = chartsSectionRef.current;
    const firstHeadline = firstHeadlineRef.current;
    const secondHeadline = secondHeadlineRef.current;
    const tabletWrapper = tabletWrapperRef.current;
    const copyBlock = copyBlockRef.current;
    const canvas = canvasRef.current;
    const masterTimeline = gsap
      .timeline()

      // render image sequence
      .add(
        getSequenceTween({
          canvasRef: canvasRef,
          duration: imageSequence.frameCount / 15,
          frameOptions: {
            srcPrefix: imageSequence.srcPrefix,
            frameCount: imageSequence.frameCount,
            width: imageSequence.width,
            height: imageSequence.height,
          },
        }),
        0
      )

      // slide up previous chart section
      .to(
        chartsSection,
        {
          y() {
            const { innerHeight } = window;
            return -innerHeight;
          },
          duration: 1.0,
        },
        0
      )

      // displays first headline, shifts up while fading in and out
      .add(textTween(firstHeadline, { fadeIn: false, pause: 0.5 }), ">")

      // pause
      .to({}, { duration: 0.25 }, ">")

      // displays second headline, shifts up while fading in and out
      .add(textTween(secondHeadline, { pause: 0.5 }), ">")

      // scale down canvas to fit svg, then shift right
      .fromTo(
        canvas,
        { scale: 1.0, borderRadius: 0 },
        {
          duration: 1,
          borderRadius: 24,
          ease: "power1.in",
          scale() {
            const display = svgRefs.displayRef.current;
            const displayHeight = display.getBoundingClientRect().height;
            const canvasHeight = canvas.getBoundingClientRect().height;
            return displayHeight / canvasHeight;
          },
        },
        ">"
      )

      // shift graphics to right
      .to(
        tabletWrapper,
        { xPercent: 40, duration: 0.75, ease: "power1.out" },
        "<0.75"
      )

      // displays copy block, shifts up while fading in
      .fromTo(
        copyBlock,
        { opacity: 0 },
        { opacity: 1.0, ease: "power1.out", duration: 0.25 },
        ">-0.25"
      )
      .fromTo(copyBlock, { y: 32 }, { y: 0, duration: 0.25 }, "<")

      // pause
      .to({}, { duration: 0.25 });

    return {
      timeline: masterTimeline,
      scrollTriggerInstance: ScrollTrigger.create({
        id: scrollTriggerId,
        animation: masterTimeline,
        trigger: sectionsWrapper,
        start: "bottom bottom",
        end: `${900 * masterTimeline.totalDuration()}`,
        invalidateOnRefresh: true,
        scrub: true,
        pin: true,
      }),
    };
  };

  useEffect(() => {
    // intialize animation listeners
    const { timeline, scrollTriggerInstance } = applyAnimations();

    // kill listeners on unmount
    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <Section>
      <TabletWrapper ref={tabletWrapperRef}>
        <StyledPhoneSVG color={Colors.charcoal} ref={svgRefs} />
        <StyledCanvas
          srcWidth={imageSequence.width}
          srcHeight={imageSequence.height}
          ref={canvasRef}
        />
      </TabletWrapper>

      <StyledWrapper ref={firstHeadlineRef}>
        <StyledBlockHeader>{data.firstHeadline}</StyledBlockHeader>
      </StyledWrapper>

      <StyledWrapper ref={secondHeadlineRef}>
        <StyledBlockHeader>{data.secondHeadline}</StyledBlockHeader>
      </StyledWrapper>

      <StyledWrapper ref={copyBlockRef}>
        <CopyBlock>
          <BlockSupTitle>{data.copyBlock.supTitle}</BlockSupTitle>
          <StyledBlockTitle>{data.copyBlock.title}</StyledBlockTitle>
          <StyledBlockDescription>
            {data.copyBlock.description}
          </StyledBlockDescription>
          <StyledButtonLink to="/brands" text={data.copyBlock.cta} />
        </CopyBlock>
      </StyledWrapper>
    </Section>
  );
});

export default MobilePossibilities;
