import React from "react";
import * as Colors from "@constants/colors";

const PhoneLandscapeSVG = React.forwardRef(({ className }, ref) => {
  const { svgRef, displayRef } = ref;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 580.31 320"
      ref={svgRef}
      fill="none"
    >
      <g>
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M564.3,30.5v259c0,11.05-8.95,20-20,20H56.26c-11.05,0-20-8.95-20-20v-259c0-11.05,8.95-20,20-20H544.3
                        C555.35,10.5,564.3,19.45,564.3,30.5z"
        />
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M406.18,10.5L406.18,10.5c0-2.48,2.01-4.5,4.5-4.5h39c2.48,0,4.5,2.01,4.5,4.5v0H406.18z"
        />
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M343.18,10.5L343.18,10.5c0-2.48,2.01-4.5,4.5-4.5h39c2.48,0,4.5,2.01,4.5,4.5v0H343.18z"
        />
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M343.18,309.5L343.18,309.5l111,0v0c0,2.48-2.01,4.5-4.5,4.5h-102C345.19,314,343.18,311.98,343.18,309.5z"
        />
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M549.25,198.45L549.25,198.45c0,1.52-1.23,2.75-2.75,2.75l0,0c-1.52,0-2.75-1.23-2.75-2.75l0,0
                        c0-1.52,1.23-2.75,2.75-2.75l0,0C548.02,195.7,549.25,196.93,549.25,198.45z"
        />
        <path
          stroke={Colors.gray}
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          d="M549.25,122.55v62.7c0,1.52-1.23,2.75-2.75,2.75l0,0c-1.52,0-2.75-1.23-2.75-2.75v-62.7
                        c0-1.52,1.23-2.75,2.75-2.75l0,0C548.02,119.8,549.25,121.03,549.25,122.55z"
        />
      </g>
      <rect x="51.6" y="25.82" ref={displayRef} width="477.1" height="268.37" />
    </svg>
  );
});

export default PhoneLandscapeSVG;
