import styled from "styled-components";
import * as Fonts from "@constants/fonts";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ChartWrapper = styled.div`
  text-align: center;
`;

const CanvasWrapper = styled.div`
  position: relative;
  margin: 48px auto 32px;
  width: ${({ canvasWidth }) => canvasWidth};
  height: ${({ chartData }) => chartData.length * 75}px;
`;

const Header = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-weight: bold;
  color: ${Colors.white};
  font-size: 24px;
  line-height: 1.3;
  margin: 0;
`;

const Subheading = styled.p`
  font-family: ${Fonts.ProximaNovaFont};
  color: ${Colors.white};
  font-size: 16px;
  line-height: 1.4;
  margin-top: 16px;
`;

const Description = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-top: 32px;
  border-top: 1px solid ${Colors.grayDarker};
  color: ${Colors.white};
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${Fonts.ProximaNovaFont};
`;

const Above = styled.span`
  color: ${({ color }) => color};
`;

const KeyTakeaway = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${Colors.white};

  @media (max-width: ${Screens.sm}) {
    display: none;
  }
`;

const TakeawayValue = styled.p`
  font-size: 56px;
  font-family: ${Fonts.ClearfaceFont};
  font-weight: bold;
  margin: 0 0 16px 0;
  border-bottom: 1px solid ${Colors.white};
`;

const TakeawayLabel = styled.p`
  font-size: 16px;
  text-transform: uppercase;
  font-family: ${Fonts.ProximaNovaFont};
`;

export {
  Canvas,
  ChartWrapper,
  CanvasWrapper,
  Header,
  Description,
  Above,
  Subheading,
  KeyTakeaway,
  TakeawayValue,
  TakeawayLabel,
};
