import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Wrapper } from "@components/shared.styled";
import { Screens } from "@constants/screens";

const Section = styled.section`
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  background-color: ${Colors.charcoal};
  overflow: hidden;
  padding: 0 0 48px 0;
`;

const StyledWrapper = styled(Wrapper)`
  height: auto;
  padding: 80px 20px;

  @media (max-width: ${Screens.sm}) {
    padding: 80px 20px;
  }
`;

export { Section, StyledWrapper };
