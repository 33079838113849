import styled from "styled-components";
import { Wrapper } from "@components/shared.styled";
import * as Colors from "@constants/colors";
import { ClearfaceFont } from "@constants/fonts";
import PhoneSVG from "@svg-icons/phone";
import { Screens } from "@constants/screens";

const LargeHeading = styled.h1`
  font-family: ${ClearfaceFont};
  font-size: 48px;
  line-height: 1.5;
  text-align: center;
  color: ${Colors.white};
  width: 100%;
  margin: 64px 0 128px 0;

  i {
    font-style: normal;
    text-decoration: underline;
  }

  @media (max-width: ${Screens.sm}) {
    margin: 32px 0 64px;
    font-size: 28px;
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  height: ${({ frameCount }) => `${900 * (frameCount / 24) + 450}px`};
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: flex-end;
  background-color: ${({ colorName }) => Colors[colorName]};

  @media (max-width: ${Screens.sm}) {
    padding: 0 20px;
  }
`;

const StyledPhoneSVG = styled(PhoneSVG)`
  height: 60vh;
  overflow: visible;
`;

const StyledWrapper = styled(Wrapper)`
  height: auto;
`;

const SVGWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { LargeHeading, Section, StyledPhoneSVG, StyledWrapper, SVGWrapper };
