import React from "react";
import * as Colors from "@constants/colors";
import TabletScreen from "@images/introduction/tablet-screen.jpg";
import PhoneScreen from "@images/introduction/mobile-screen.jpg";

const DevicesSVG = React.forwardRef(
  ({ desktopImage, color = Colors.gray, className }, ref) => {
    const { svgRef, phoneRef, desktopRef, displayRef, tabletRef } = ref;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 997.43 524.05"
        fill="none"
        ref={svgRef}
      >
        <g ref={desktopRef}>
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M745.91,130.45v322.6c0,8.3-6.7,15-15,15h-464.4c-8.3,0-15-6.7-15-15
                    v-322.6c0-8.3,6.7-15,15-15h464.5C739.21,115.45,745.91,122.15,745.91,130.45z"
          />
          <ellipse
            vectorEffect="non-scaling-stroke"
            stroke={color}
            cx="498.71"
            cy="429.75"
            rx="14.5"
            ry="14"
          />
          <rect
            x="440.41"
            y="520.05"
            vectorEffect="non-scaling-stroke"
            stroke={color}
            width="119"
            height="4"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M460.41,468.05l-10,42h99l-10-42H460.41z"
          />
          <polygon
            vectorEffect="non-scaling-stroke"
            stroke={color}
            points="559.41,520.05 440.41,520.05 450.41,510.05 549.41,510.05 	"
          />

          <g ref={displayRef}>
            <defs>
              <path
                id="desktop-display"
                d="M722.71,391.4h-448c-3.31,0-6-2.69-6-6V138.65c0-3.31,2.69-6,6-6h448c3.31,0,6,2.69,6,6V385.4
                                C728.71,388.71,726.03,391.4,722.71,391.4z"
              />
            </defs>
            <clipPath id="desktop-screen">
              <use
                href="#desktop-display"
                style={{ overflow: "overflow:visible" }}
              />
            </clipPath>
            <g style={{ clipPath: "url(#desktop-screen)" }}>
              <image
                style={{ overflow: "overflow:visible" }}
                width="1280"
                height="720"
                href={desktopImage}
                transform="matrix(0.3594 0 0 0.3594 268.7132 132.65)"
              />
            </g>
          </g>
        </g>

        <g ref={phoneRef}>
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M121.41,216.5h80.7c4.4,0,8,3.6,8,8V415c0,4.4-3.6,8-8,8h-80.7
                        c-4.4,0-8-3.6-8-8V224.5C113.41,220.1,117.01,216.5,121.41,216.5z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M112.01,268.9h1.4v-17.1h-1.4c-0.1,0-0.2,0.1-0.2,0.2v16.7
                        C111.81,268.8,111.91,268.9,112.01,268.9z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M210.11,290.1h1.4c0.1,0,0.2-0.1,0.2-0.2v-37.8c0-0.1-0.1-0.2-0.2-0.2
                        h-1.4V290.1z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M112.01,290.1h1.4V273h-1.4c-0.1,0-0.2,0.1-0.2,0.2v16.7
                        C111.81,290,111.91,290.1,112.01,290.1z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M173.11,224.2L173.11,224.2c0.2,0,0.4,0.2,0.4,0.4l0,0
                        c0,0.2-0.2,0.4-0.4,0.4l0,0c-0.2,0-0.4-0.2-0.4-0.4l0,0C172.71,224.4,172.91,224.2,173.11,224.2z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M150.41,224.2h18.7c0.2,0,0.4,0.2,0.4,0.4l0,0c0,0.2-0.2,0.4-0.4,0.4
                        h-18.7c-0.2,0-0.4-0.2-0.4-0.4l0,0C150.01,224.4,150.21,224.2,150.41,224.2z"
          />
          <g>
            <defs>
              <path
                id="phone-display"
                d="M201.44,417.37h-79.37c-1.66,0-3-1.34-3-3V235.54c0-1.66,1.34-3,3-3h79.37c1.66,0,3,1.34,3,3v178.83
                                C204.44,416.03,203.1,417.37,201.44,417.37z"
              />
            </defs>
            <clipPath id="phone-screen">
              <use href="#phone-display" style={{ overflow: "visible" }} />
            </clipPath>
            <g style={{ clipPath: "url(#phone-screen)" }}>
              <image
                href={PhoneScreen}
                height="721"
                width="333"
                transform="matrix(0.2564 0 0 0.2564 119.0764 232.5407)"
              />
            </g>
          </g>
        </g>

        <g ref={tabletRef}>
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M800.33,179.47h183.1c6.6,0,12,5.4,12,12v258.5c0,6.6-5.4,12-12,12h-183.1
                        c-6.6,0-12-5.4-12-12v-258.5C788.33,184.87,793.73,179.47,800.33,179.47z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M960.33,177.77v1.8h20.5v-1.8c0-0.1-0.1-0.2-0.2-0.2h-20
                        C960.43,177.47,960.33,177.57,960.33,177.77z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M995.43,213.97h1.8c0.1,0,0.2-0.1,0.2-0.2v-20.6c0-0.1-0.1-0.2-0.2-0.2
                        h-1.8V213.97z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M995.43,239.97h1.8c0.1,0,0.2-0.1,0.2-0.2v-20.6c0-0.1-0.1-0.2-0.2-0.2
                        h-1.8V239.97z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M877.93,188.57h23c0.3,0,0.5,0.2,0.5,0.5l0,0c0,0.3-0.2,0.5-0.5,0.5h-23
                        c-0.3,0-0.5-0.2-0.5-0.5l0,0C877.43,188.77,877.63,188.57,877.93,188.57z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke={color}
            d="M905.93,188.57L905.93,188.57c0.3,0,0.5,0.2,0.5,0.5l0,0
                        c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5l0,0C905.43,188.77,905.63,188.57,905.93,188.57z"
          />
          <g>
            <defs>
              <path
                id="tablet-display"
                d="M984.75,454.29H799c-1.66,0-3-1.34-3-3V201.63c0-1.66,1.34-3,3-3h185.75c1.66,0,3,1.34,3,3v249.66
                                C987.75,452.95,986.41,454.29,984.75,454.29z"
              />
            </defs>
            <clipPath id="tablet-screen">
              <use
                href="#tablet-display"
                style={{ overflow: "overflow:visible" }}
              />
            </clipPath>
            <g style={{ clipPath: "url(#tablet-screen)" }}>
              <image
                href={TabletScreen}
                height="480"
                width="360"
                transform="matrix(0.5326 0 0 0.5326 796.0023 198.6294)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
);

export default DevicesSVG;
