import React from "react";
import * as Colors from "@constants/colors";

const PhoneAltSVG = React.forwardRef(({ className }, ref) => {
  const { svgRef, displayRef } = ref;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 320 640"
      fill="none"
      ref={svgRef}
    >
      <path
        fill={Colors.charcoal}
        d="M309.5,105.98L309.5,105.98v-80c0-11.05-8.95-20-20-20h-259c-11.05,0-20,8.95-20,20v80h0
                c-2.48,0-4.5,2.01-4.5,4.5v39c0,2.48,2.01,4.5,4.5,4.5h0v15h0c-2.48,0-4.5,2.01-4.5,4.5v39c0,2.48,2.01,4.5,4.5,4.5h0V593.7
                c0,11.05,8.95,20,20,20h259c11.05,0,20-8.95,20-20V216.98l0,0c2.48,0,4.5-2.01,4.5-4.5v-102C314,108,311.98,105.98,309.5,105.98z"
      />
      <path
        fill={Colors.white}
        d="M198.45,21.05c-1.52,0-2.75,1.23-2.75,2.75s1.23,2.75,2.75,2.75c1.52,0,2.75-1.23,2.75-2.75
                S199.97,21.05,198.45,21.05z M185.25,21.05h-62.7c-1.52,0-2.75,1.23-2.75,2.75s1.23,2.75,2.75,2.75h62.7c1.52,0,2.75-1.23,2.75-2.75
                S186.77,21.05,185.25,21.05z"
      />
      <rect
        ref={displayRef}
        x="25.82"
        y="41.61"
        width="268.37"
        height="556.77"
      />
    </svg>
  );
});

export default PhoneAltSVG;
