import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Wrapper } from "@components/shared.styled";
import { ClearfaceFont, ProximaNovaFont } from "@constants/fonts";
import PhoneLandscapeSVG from "@svg-icons/phone-landscape";

const Body = styled.p`
  font-family: ${ProximaNovaFont};
  font-size: 28px;
  line-height: 40px;
  margin: 32px auto 0 auto;
  max-width: 640px;
`;

const ContentWrapper = styled(Wrapper)`
  height: auto;
  text-align: center;
  color: ${Colors.white};
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  border-radius: 24px;
  z-index: 3;

  @media (orientation: portrait) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (max-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (min-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }
`;

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: ${Colors.charcoal};
  overflow: hidden;
`;

const StyledWrapper = styled(Wrapper)`
  height: auto;
`;

const StyledPhoneLandscapeSVG = styled(PhoneLandscapeSVG)`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  overflow: visible;
`;

const Title = styled.h2`
  font-family: ${ClearfaceFont};
  font-size: 44px;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 500px;
`;

export {
  Body,
  ContentWrapper,
  OverlayContent,
  StyledCanvas,
  Section,
  StyledPhoneLandscapeSVG,
  StyledWrapper,
  Title,
};
