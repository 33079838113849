import styled from "styled-components";
import { Wrapper } from "@components/shared.styled";
import * as Colors from "@constants/colors";
import { ClearfaceFont } from "@constants/fonts";
import DevicesSVG from "@svg-icons/devices";
import { Screens } from "@constants/screens";

const LargeHeading = styled.h1`
  font-family: ${ClearfaceFont};
  font-size: 48px;
  line-height: 68px;
  text-align: center;
  color: ${Colors.white};
  width: 100%;
  margin: 64px 0 128px 0;

  i {
    font-style: normal;
    text-decoration: underline;
  }

  @media (max-width: ${Screens.md}) {
    margin-top: 0;
    font-size: 40px;
    line-height: 56px;
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  height: 5850px;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: flex-end;
  background-color: ${({ colorName }) => Colors[colorName]};
`;

const StyledDevicesSVG = styled(DevicesSVG)`
  height: 60vh;
  overflow: visible;
`;

const StyledWrapper = styled(Wrapper)`
  height: auto;
`;

const SVGWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { LargeHeading, Section, StyledDevicesSVG, StyledWrapper, SVGWrapper };
