import React, { useEffect, useRef } from "react";
import { applyFadeInAnimation } from "@helpers/animations";
import { BarChart, DonutChart } from "@components/charts";
import { Section, StyledWrapper } from "./charts.styled.js";

const Charts = React.forwardRef(({ data }, ref) => {
  const { chartsSectionRef } = ref;
  const chartRefs = [useRef(null), useRef(null), useRef(null)];

  // apply animations
  useEffect(() => {
    const animations = chartRefs.forEach(chartRef =>
      applyFadeInAnimation(chartRef)
    );

    // destroy listeners on unmount
    return () => {
      if (animations) {
        animations.forEach(({ timeline, scrollTriggerInstance }) => {
          timeline.pause(0).kill();
          scrollTriggerInstance.kill(true);
        });
      }
    };
  });

  const mapChartData = chartData => ({
    ...chartData,
    labels: chartData.data.map(({ label }) => label),
    data: chartData.data.map(({ value }) => Number(value)),
  });

  return (
    <Section ref={chartsSectionRef}>
      <StyledWrapper ref={chartRefs[0]}>
        <BarChart chartData={mapChartData(data.digitalMedia)} />
      </StyledWrapper>

      <StyledWrapper ref={chartRefs[1]}>
        <BarChart chartData={mapChartData(data.clickThrough)} />
      </StyledWrapper>

      <StyledWrapper ref={chartRefs[2]}>
        <DonutChart chartData={mapChartData(data.channelsPreferences)} />
      </StyledWrapper>
    </Section>
  );
});

export default Charts;
