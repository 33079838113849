import React, { useEffect, useRef } from "react";
import { applyFadeInAnimation } from "@helpers/animations";
import { BarChart, DonutChart } from "@components/charts";
import { Section, StyledWrapper } from "./mobile-charts.styled.js";

const MobileCharts = React.forwardRef(({ data }, ref) => {
  const { chartsSectionRef } = ref;
  const chartRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    chartRefs.forEach(chartRef => applyFadeInAnimation(chartRef));
  });

  const mapChartData = chartData => ({
    ...chartData,
    labels: chartData.data.map(({ label }) => label),
    data: chartData.data.map(({ value }) => Number(value)),
  });

  return (
    <Section ref={chartsSectionRef}>
      <StyledWrapper ref={chartRefs[0]}>
        <BarChart
          height="200px"
          width="100%"
          isHorizontal={false}
          chartData={mapChartData(data.digitalMedia)}
        />
      </StyledWrapper>

      <StyledWrapper ref={chartRefs[1]}>
        <BarChart
          height="200px"
          width="100%"
          isHorizontal={false}
          chartData={mapChartData(data.clickThrough)}
        />
      </StyledWrapper>

      <StyledWrapper ref={chartRefs[2]}>
        <DonutChart
          width="100%"
          chartData={mapChartData(data.channelsPreferences)}
        />
      </StyledWrapper>
    </Section>
  );
});

export default MobileCharts;
