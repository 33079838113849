import React from "react";
import Image from "@components/image";
import * as Colors from "@constants/colors";
import {
  SmallHeader,
  LargeHeader,
  Info,
  QuoteWrapper,
  QuoteContainer,
  AuthorImg,
  AuthorInfo,
  Attribution,
  Author,
  AuthorTitle,
  StyledSection,
  Text,
} from "./why-we-do-it.styled";

const WhyWeDoIt = ({ data }) => {
  const {
    smallHeader,
    largeHeader,
    info,
    quoteText,
    displayAuthorTitle,
    quoteAuthor,
    authorTitle,
    authorImg,
  } = data;

  return (
    <StyledSection backgroundColor={Colors.seafoamLighter}>
      <div>
        <SmallHeader>{smallHeader}</SmallHeader>
        <LargeHeader>{largeHeader}</LargeHeader>
        <Info>{info}</Info>
        <QuoteWrapper>
          <QuoteContainer>
            <Text>{quoteText}</Text>
            <Attribution>
              {authorImg.displayImage && (
                <AuthorImg>
                  <Image width="100%" image={authorImg.src} alt={authorImg.alt} />
                </AuthorImg>
              )}
              <AuthorInfo>
                <Author>{quoteAuthor}</Author>
                {displayAuthorTitle && <AuthorTitle>{authorTitle}</AuthorTitle>}
              </AuthorInfo>
            </Attribution>
          </QuoteContainer>
        </QuoteWrapper>
      </div>
    </StyledSection>
  );
};

export default WhyWeDoIt;
