import React, { useRef } from "react";
import MobileCharts from "./charts/mobile-charts";
import MobilePossibilities from "./possibilities/mobile-possibilities";
import { SectionWrapper } from "./mobile-charts-possibilities.styled.js";

const MobileChartsPossibilities = ({ data }) => {
  const middleSectionRefs = {
    chartsSectionRef: useRef(null),
    sectionsWrapperRef: useRef(null),
  };

  return (
    <SectionWrapper ref={middleSectionRefs.sectionsWrapperRef}>
      <MobileCharts data={data.charts} ref={middleSectionRefs} />
      <MobilePossibilities data={data.possibilities} ref={middleSectionRefs} />
    </SectionWrapper>
  );
};

export default MobileChartsPossibilities;
