import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { getSequenceTween } from "@helpers/animations";
import {
  Body,
  ContentWrapper,
  OverlayContent,
  StyledCanvas,
  Section,
  StyledPhoneLandscapeSVG,
  Title,
} from "./email-advantage.styled.js";

gsap.registerPlugin(ScrollTrigger);

const imageSequence = {
  srcPrefix: "/city/city_",
  frameCount: 25,
  width: 1024,
  height: 576,
};

const EmailAdvantage = ({ data }) => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);
  const canvasRef = useRef(null);
  const svgRefs = { svgRef: useRef(null), displayRef: useRef(null) };

  const getCanvasTimeline = () => {
    const { srcPrefix, frameCount, width, height } = imageSequence;

    return (
      gsap
        .timeline()
        // render image sequence
        .add(
          getSequenceTween({
            canvasRef: canvasRef,
            duration: 2.5,
            frameOptions: {
              srcPrefix,
              frameCount,
              width,
              height,
            },
          }),
          "<"
        )
    );
  };

  const getContentTimeline = () => {
    const contentWrapper = contentRef.current;
    const svgGraphic = svgRefs.svgRef.current;
    const display = svgRefs.displayRef.current;
    const canvas = canvasRef.current;

    return (
      gsap
        .timeline()

        // scale canvas to full screen
        .fromTo(
          canvas,
          {
            scale() {
              const { innerWidth, innerHeight } = window;
              const canvasHeight = canvas.getBoundingClientRect().height;
              const canvasWidth = canvas.getBoundingClientRect().width;
              const displayHeight = display.getBoundingClientRect().height;
              const displayWidth = display.getBoundingClientRect().width;

              if (
                innerHeight < innerWidth &&
                innerWidth / innerHeight >= canvasWidth / canvasHeight
              ) {
                return displayWidth / innerWidth;
              } else {
                return displayHeight / innerHeight;
              }
            },
            borderRadius: 24,
          },
          { scale: 1.0, borderRadius: 0, duration: 0.5, ease: "power1.inOut" }
        )

        // fadeout phone svg
        .to(svgGraphic, { opacity: 0, duration: 0.25 }, "<")

        // dim background canvas (0.5s)
        .to(canvas, { opacity: 0.25, duration: 0.5 }, "<")

        // fade in text (0.5s)
        .fromTo(
          contentWrapper,
          { opacity: 0 },
          { opacity: 1.0, duration: 0.25 },
          ">"
        )
        .fromTo(contentWrapper, { y: 32 }, { y: 0, duration: 0.5 }, "<")
        .to({}, { duration: 0.75 }, ">")

        // fade out canvas (0.5)
        .to(canvas, { opacity: 0, duration: 0.5 }, ">")
    );
  };

  const applyAnimations = () => {
    const scrollTriggerId = "emailAdvantageScrollTrigger";
    const section = sectionRef.current;
    const canvasTimeline = getCanvasTimeline();
    const contentTimeline = getContentTimeline();
    const masterTimeline = gsap
      .timeline()
      .add(canvasTimeline)
      .add(contentTimeline, "<");

    return {
      timeline: masterTimeline,
      scrollTriggerInstance: ScrollTrigger.create({
        animation: masterTimeline,
        id: scrollTriggerId,
        trigger: section,
        invalidateOnRefresh: true,
        start: "bottom bottom",
        end: `${900 * masterTimeline.totalDuration()}`,
        scrub: true,
        pin: true,
      }),
    };
  };

  useEffect(() => {
    const { timeline, scrollTriggerInstance } = applyAnimations();
    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <Section ref={sectionRef}>
      <StyledPhoneLandscapeSVG ref={svgRefs} />
      <StyledCanvas
        ref={canvasRef}
        srcWidth={imageSequence.width}
        srcHeight={imageSequence.height}
      />
      <OverlayContent>
        <ContentWrapper ref={contentRef}>
          <Title>{data.title}</Title>
          <Body>{data.body}</Body>
        </ContentWrapper>
      </OverlayContent>
    </Section>
  );
};

export default EmailAdvantage;
