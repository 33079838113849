import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import Chart from "chart.js/auto";
import abbreviateNumber from "@helpers/chart-data";
import { ScrollTrigger } from "gsap/all";
import {
  Canvas,
  ChartWrapper,
  CanvasWrapper,
  Header,
  Description,
  Subheading,
  Above,
  KeyTakeaway,
  TakeawayValue,
  TakeawayLabel,
} from "./donut-chart.styled";

Chart.register(ChartDataLabels);

const DonutChart = ({
  width = "100%",
  height = "400px",
  chartData: {
    id,
    header,
    subheading,
    description,
    labels = [],
    data = [],
    percentage = false,
    takeaway,
  },
}) => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // key legend layout is updated based on viewport size
  useEffect(() => {
    // update mobile state
    const handleResize = () => {
      const isMobileResolution =
        window.innerWidth <= Number.parseInt(Screens.md);
      if (isMobileResolution !== isMobile) {
        setIsMobile(isMobileResolution);
      }
    };

    if (typeof window !== "undefined") {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    // remove listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    // mobile vs desktop config
    const responsiveConfig = isMobile
      ? {
          legend: {
            position: "bottom",
            labels: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
              padding: 25,
              boxWidth: 16,
            },
          },
        }
      : {
          legend: {
            position: "left",
            labels: {
              font: {
                family: Fonts.ProximaNovaFont,
                size: 14,
              },
              color: "white",
              padding: 50,
              boxWidth: 16,
            },
          },
        };

    // main config
    const chartConfig = {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: [
              Colors.goldenHourLight,
              Colors.goldenHour,
              Colors.sunsetCoral,
              Colors.seafoamLite,
              Colors.beachTeal,
            ],
            borderWidth: 0,
            cutout: isMobile ? "50%" : "75%",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          datalabels: {
            clip: false,
            anchor: "center",
            offset: 0,
            formatter: value => {
              if (percentage === true) {
                return `${value}%`;
              } else {
                return abbreviateNumber(value);
              }
            },
            clamp: true,
            align: "center",
            color: Colors.charcoal,
            font: {
              weight: "bold",
              family: Fonts.ProximaNovaFont,
            },
          },
          ...responsiveConfig,
          tooltip: {
            enabled: false,
          },
        },

        animation: {
          duration: 1200,
        },
        layout: {
          padding: isMobile
            ? 0
            : {
                right: 200,
              },
        },
      },
    };

    // animation
    const canvasEl = canvasRef.current;
    const canvasContext = canvasEl.getContext("2d");
    let chart = new Chart(canvasContext, chartConfig);
    const scrollTriggerInstance = ScrollTrigger.create({
      trigger: canvasEl,
      start: "top bottom",
      end: "center bottom",
      invalidateOnRefresh: true,
      onEnter: () => chart.destroy(),
      onLeave: () => (chart = new Chart(canvasContext, chartConfig)),
    });

    // destroy scroll animations on unmount
    return () => {
      chart.destroy();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <ChartWrapper>
      {header && <Header>{header}</Header>}
      {subheading && <Subheading>{subheading}</Subheading>}

      <CanvasWrapper
        className="chart-container"
        canvasWidth={width}
        canvasHeight={height}
        chartData={data}
      >
        <Canvas id={`donut-chart-${id}`} ref={canvasRef} />
        {takeaway.value && (
          <KeyTakeaway>
            <TakeawayValue>{takeaway.value}</TakeawayValue>
            <TakeawayLabel>{takeaway.label}</TakeawayLabel>
          </KeyTakeaway>
        )}
      </CanvasWrapper>

      {description && (
        <Description>
          <Above color={Colors.beachTeal}>Above: </Above>
          {description}
        </Description>
      )}
    </ChartWrapper>
  );
};

export default DonutChart;
