import React from "react";
import GetStarted from "@modules/get-started";
import MobileIntroductionShowcase from "@modules/introduction-showcase/mobile-introduction-showcase";
import Mission from "@modules/mission";
import MobileEmailAdvantage from "@modules/email-advantage/mobile-email-advantage";
import MobileChartsPossibilities from "@modules/charts-possibilities/mobile-charts-possibilities";
import WhyWeDoIt from "@modules/why-we-do-it";
import Partnership from "@modules/partnership";

const MobileHome = ({ data }) => (
  <div>
    <MobileIntroductionShowcase data={data.introductionShowcase} />
    <Mission data={data.missionText} />
    <MobileEmailAdvantage data={data.emailAdvantage} />
    <MobileChartsPossibilities data={data} />
    <WhyWeDoIt data={data.why} />
    <Partnership data={data.partnership} />
    <GetStarted />
  </div>
);

export default MobileHome;
