import React, { useRef } from "react";
import Charts from "./charts";
import Possibilities from "./possibilities";
import { SectionWrapper } from "./charts-possibilities.styled.js";

const ChartsPossibilities = ({ data }) => {
  const middleSectionRefs = {
    chartsSectionRef: useRef(null),
    sectionsWrapperRef: useRef(null),
  };

  return (
    <SectionWrapper ref={middleSectionRefs.sectionsWrapperRef}>
      <Charts data={data.charts} ref={middleSectionRefs} />
      <Possibilities data={data.possibilities} ref={middleSectionRefs} />
    </SectionWrapper>
  );
};

export default ChartsPossibilities;
