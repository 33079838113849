import React, { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Home from "@modules/home";
import { ScrollTrigger } from "gsap/all";
import { graphql, useStaticQuery } from "gatsby";

const IndexPage = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/pages-data/home/" }) {
          frontmatter {
            scrollIndicatorText
            introduction
            headline
            takeaways {
              title
              description
            }
            newsletterTitle
            newsletters {
              colorVal
              src {
                publicURL
              }
            }
            missionText {
              isHighlighted
              text
            }
            emailAdvantage {
              title
              body
            }
            charts {
              digitalMedia {
                id
                header
                barColor
                data {
                  label
                  value
                }
                description
              }
              clickThrough {
                id
                header
                barColor
                data {
                  label
                  value
                }
                description
              }
              channelsPreferences {
                id
                header
                data {
                  label
                  value
                }
                description
                takeaway {
                  label
                  value
                }
              }
            }
            possibilities {
              firstHeadline
              secondHeadline
              copyBlock {
                supTitle
                title
                description
                cta
              }
            }
            why {
              smallHeader
              largeHeader
              info
              quoteText
              quoteAuthor
              displayAuthorTitle
              authorTitle
              authorImg {
                displayImage
                src {
                  publicURL
                }
                alt
              }
            }
            partnership {
              backgroundColor
              smallHeader
              largeHeader
            }
          }
        }
      }
    `
  );
  const { introduction, headline, takeaways, newsletters, ...rest } = frontmatter;
  const data = {
    introductionShowcase: {
      introduction: {
        headline: introduction,
      },
      showcase: {
        headline: headline,
        takeaways,
        newsletters,
      },
    },
    ...rest,
  };

  useEffect(() => {
    // manually recalculate positioning of all ScrollTriggers
    ScrollTrigger.refresh();
  });

  return (
    <Layout>
      <Helmet>
        <title>Atwave</title>
      </Helmet>
      <Home data={data} />
    </Layout>
  );
};

export default IndexPage;
