import styled from "styled-components";
import * as Colors from "@constants/colors";

const SectionsWrapper = styled.div`
  background-color: ${Colors.charcoal};
  position: relative;
  width: 100%;
`;

const StyledCanvas = styled.canvas`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  z-index: 99;

  @media (orientation: landscape) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }

  @media (orientation: portrait) and (max-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcHeight}/${srcWidth}`}) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }

  @media (orientation: portrait) and (min-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcHeight}/${srcWidth}`}) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }
`;

export { SectionsWrapper, StyledCanvas };
