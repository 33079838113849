import React, { useRef, createRef, useEffect } from "react";
import * as Colors from "@constants/colors";
import {
  MissionText,
  TextWrapper,
  Highlight,
  Section,
} from "./mission.styled.js";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const Mission = ({ data: missionText }) => {
  const contentRef = useRef(null);
  const highlights = missionText.filter(({ isHighlighted }) => isHighlighted);
  const highlightRefs = highlights.map(() => createRef());
  let highlightCount = -1;

  const applyAnimation = () => {
    const contentBlock = contentRef.current;
    const highlightEls = highlightRefs.map(ref => ref.current);
    const timeline = gsap.timeline();

    highlightEls.forEach(element => {
      const getDuration = () => {
        const rects = element.getClientRects();
        let duration = 0;

        for (let i = 0; i !== rects.length; i += 1) {
          let rect = rects[i];
          duration += rect.width;
        }

        return duration;
      };

      timeline.fromTo(
        element,
        {
          background: `linear-gradient(to right, ${Colors.seafoamLite} 0%, ${Colors.white} 0%)`,
        },
        {
          background: `linear-gradient(to right, ${Colors.seafoamLite} 100%, ${Colors.white} 0%)`,
          ease: "power1.inOut",
          duration: getDuration,
        }
      );
    });

    return {
      timeline,
      scrollTriggerInstance: ScrollTrigger.create({
        animation: timeline,
        trigger: contentBlock,
        start: "bottom bottom",
        end: "200%",
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
      }),
    };
  };

  useEffect(() => {
    const { timeline, scrollTriggerInstance } = applyAnimation();

    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <Section yPadding="0" ref={contentRef} backgroundColor={Colors.white}>
      <TextWrapper>
        <MissionText>
          {missionText.map(({ text, isHighlighted }) => {
            if (isHighlighted === true) {
              highlightCount += 1;
              return (
                <Highlight
                  ref={highlightRefs[highlightCount]}
                  key={text}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              );
            } else {
              return (
                <span key={text} dangerouslySetInnerHTML={{ __html: text }} />
              );
            }
          })}
        </MissionText>
      </TextWrapper>
    </Section>
  );
};

export default Mission;
