import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { navbarHeight } from "@constants/dimensions";
import { ClearfaceFont } from "@constants/fonts";
import { Wrapper } from "@components/shared.styled";
import { StyledSection } from "@components/section/section.styled";

const Section = styled(StyledSection)`
  height: 100vh;
  padding-top: ${navbarHeight}px;
`;

const MissionText = styled.div`
  font-family: ${ClearfaceFont};
  font-size: 56px;
  color: ${Colors.charcoal};
  line-height: 1.4;
  width: 75%;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    font-size: 32px;
    text-align: center;
  }
`;

const TextWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
`;

const Highlight = styled.span`
  background: linear-gradient(
    to right,
    ${Colors.seafoamLite} 50%,
    ${Colors.seafoamLite} 50%
  );
  text-decoration: underline;
`;

export { MissionText, TextWrapper, Highlight, Section };
