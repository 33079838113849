import React from "react";
import * as Colors from "@constants/colors";

const ScrollIndicatorSVG = React.forwardRef(({ className }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 112 112"
      ref={ref}
      fill="none"
    >
      <path
        stroke={Colors.white}
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
        d="M56,7c9.79,0,17.76,7.97,17.76,17.76v23.65c0,9.79-7.97,17.76-17.76,17.76c-9.79,0-17.76-7.97-17.76-17.76V24.76
                    C38.24,14.97,46.21,7,56,7 M56,6L56,6c-10.36,0-18.76,8.4-18.76,18.76v23.65c0,10.36,8.4,18.76,18.76,18.76h0
                    c10.36,0,18.76-8.4,18.76-18.76V24.76C74.76,14.4,66.36,6,56,6L56,6z"
      />
      <line
        stroke={Colors.white}
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
        x1="56"
        y1="26.93"
        x2="56"
        y2="17.22"
      />
      <polyline
        stroke={Colors.white}
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
        points="65.51,96.49 56,106 46.49,96.49 "
      />
      <polyline
        stroke={Colors.white}
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
        points="65.51,86.28 56,95.78 46.49,86.28 "
      />
    </svg>
  );
});

export default ScrollIndicatorSVG;
