import styled from "styled-components";
import { ClearfaceFont, ProximaNovaFont } from "@constants/fonts";
import * as Colors from "@constants/colors";
import { navbarHeight } from "@constants/dimensions";
import PhoneSVG from "@svg-icons/phone";
import PhoneAltSVG from "@svg-icons/phone-alt";
import { Wrapper } from "@components/shared.styled";
import { Screens } from "@constants/screens";

const BlockDescription = styled.p`
  font-size: 22px;
  line-height: 32px;
  font-family: ${ProximaNovaFont};
  margin: 24px 0 0 0;

  @media (max-width: ${Screens.md}) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const BlockTitle = styled.h2`
  font-size: 38px;
  line-height: 1.5;
  font-family: ${ClearfaceFont};
  margin: 0;

  @media (max-width: ${Screens.md}) {
    font-size: 28px;
    width: 100%;
  }
`;

const ColorSlides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const CopyBlock = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 50%;
  padding: 0 8%;
  top: 0;
  left: 0;
  z-index: 5;

  div {
    margin: 0 auto;
    max-width: 500px;
  }

  @media (max-width: ${Screens.md}) {
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
`;

const SectionCanvas = styled.canvas`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;

  @media (orientation: portrait) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (max-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (min-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }
`;

const FloatingImage = styled.img`
  width: 30%;
  height: auto;

  :not(:last-child) {
    margin-right: 5%;
  }
`;

const LargeHeader = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${ClearfaceFont};
  font-size: 48px;
  line-height: 68px;
  text-align: center;
  width: 100%;
  margin: 0;
  z-index: 4;

  i {
    font-style: normal;
    text-decoration: underline;
  }

  @media (max-width: ${Screens.md}) {
    font-size: 32px;
    line-height: 44px;
  }
`;

const NewsletterImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  z-index: ${props => props.zIndex};
`;

const NewslettersMask = styled.div`
  position: absolute;
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

const FloatingNewsletters = styled.div`
  position: absolute;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transform: translateX(calc(85% - 24px));
`;

const NewslettersWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  z-index: 40;
`;

const PhoneWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Section = styled.section`
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - ${navbarHeight}px);
  background-color: ${({ colorName }) => Colors[colorName]};
  color: ${Colors.white};
`;

const ShowcaseWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.colorVal};
  z-index: ${props => props.zIndex};
`;

const StyledPhoneSVG = styled(PhoneSVG)`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 80%;
  max-height: 640px;
  overflow: visible;
`;

const StyledPhoneAltSVG = styled(PhoneAltSVG)`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
  max-height: 640px;
  overflow: visible;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 25%;
  padding-right: 8%;
  padding-left: 8%;
  top: 0;
  left: 0;
  z-index: 5;

  @media (max-width: ${Screens.md}) {
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
`;

const TakeawayCanvas = styled.canvas`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  height: calc((16 / 9) * 100vw);
  width: 100vw;
  z-index: 3;
`;

const SectionCanvasWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  z-index: 99;

  @media (orientation: portrait) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (max-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    height: 100vh;
    width: ${({ srcWidth, srcHeight }) =>
      `calc((${srcWidth}/${srcHeight}) * 100vh)`};
  }

  @media (orientation: landscape) and (min-aspect-ratio: ${({
      srcWidth,
      srcHeight,
    }) => `${srcWidth}/${srcHeight}`}) {
    width: 100vw;
    height: ${({ srcWidth, srcHeight }) =>
      `calc((${srcHeight}/${srcWidth}) * 100vw)`};
  }
`;

export {
  ColorSlides,
  CopyBlock,
  BlockTitle,
  BlockDescription,
  SectionCanvas,
  FloatingImage,
  LargeHeader,
  NewsletterImage,
  FloatingNewsletters,
  NewslettersWrapper,
  NewslettersMask,
  PhoneWrapper,
  Section,
  Slide,
  ShowcaseWrapper,
  StyledPhoneSVG,
  StyledPhoneAltSVG,
  TitleBlock,
  TakeawayCanvas,
  SectionCanvasWrapper,
};
