import React, { createRef, useRef, useEffect } from "react";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import * as Colors from "@constants/colors";
import { gsap, ScrollTrigger } from "gsap/all";
import { getSequenceTween, textTween } from "@helpers/animations";
import {
  BlockDescription,
  BlockTitle,
  ColorSlides,
  CopyBlock,
  SectionCanvas,
  LargeHeader,
  NewsletterImage,
  NewslettersMask,
  NewslettersWrapper,
  PhoneWrapper,
  Section,
  ShowcaseWrapper,
  Slide,
  StyledPhoneSVG,
  StyledPhoneAltSVG,
  TitleBlock,
  TakeawayCanvas,
  SectionCanvasWrapper,
} from "./mobile-showcase.styled.js";

gsap.registerPlugin(ScrollTrigger);

const MobileShowcase = React.forwardRef(({ data, imageSequences }, ref) => {
  const {
    introductionSectionRef,
    sectionsWrapperRef,
    showcaseSectionRef,
    introductionHeadlineRef,
  } = ref;
  const svgRefs = { svgRef: useRef(null), displayRef: useRef(null) };
  const svgAltRefs = { svgRef: useRef(null), displayRef: useRef(null) };
  const largeHeaderRef = useRef(null);
  const phoneWrapperRef = useRef(null);
  const { newsletterTransition } = imageSequences;
  const { sectionTransition } = imageSequences;

  // Section transition refs
  const sectionCanvasRef = useRef(null);
  const sectionCanvasWrapperRef = useRef(null);

  // Takeaway refs
  const takeawaysData = data.takeaways;
  const { takeaways } = imageSequences;
  const takeawayCanvasRef = useRef(null);
  const takeawayRefs = takeaways.map(() => createRef());

  // Newsletter refs
  const { newsletters } = data;
  const newsletterColorSlideRef = newsletters.map(() => createRef());
  const initialColorSlideRef = useRef(null);
  const newsletterTitleRef = useRef(null);
  const newsletterWrapperRef = useRef(null);
  const newsletterMaskRef = useRef(null);
  const newsletterImageRefs = newsletters.map(() => createRef());

  const getIntroductionTimeline = () => {
    const introductionHeadline = introductionHeadlineRef.current;

    return gsap
      .timeline()
      .add(textTween(introductionHeadline, { pause: 0.25, fadeOut: false }));
  };

  const getSectionTransitionTimeline = () => {
    const takeawayCanvas = takeawayCanvasRef.current;
    const sectionCanvas = sectionCanvasRef.current;
    const sectionCanvasWrapper = sectionCanvasWrapperRef.current;
    const display = svgRefs.displayRef.current;
    const introductionSection = introductionSectionRef.current;
    const showcaseSection = showcaseSectionRef.current;
    const phoneWrapper = phoneWrapperRef.current;
    const largeHeader = largeHeaderRef.current;

    return (
      gsap
        .timeline()

        // set takeaway canvas dimensions via scaling
        .set(takeawayCanvas, {
          scale() {
            const displayHeight = display.getBoundingClientRect().height;
            const canvasHeight = takeawayCanvas.getBoundingClientRect().height;
            return displayHeight / canvasHeight;
          },
        })

        // slide introduction section up
        .to(
          introductionSection,
          {
            y() {
              const { innerHeight } = window;
              return -innerHeight;
            },
            duration: 0.5,
            ease: "power2.in",
          },
          0
        )

        // reset introduction section position so that "scrollTrigger refresh" recalculates correctly
        .set(introductionSection, {
          y: 0,
          zIndex: showcaseSection.style.zIndex - 1,
        })

        // scroll through image sequence canvas
        .add(
          getSequenceTween({
            canvasRef: sectionCanvasRef,
            duration: sectionTransition.frameCount / 10,
            frameOptions: {
              srcPrefix: sectionTransition.srcPrefix,
              frameCount: sectionTransition.frameCount,
              width: sectionTransition.width,
              height: sectionTransition.height,
            },
          }),
          0
        )

        // size down canvas to fit svg graphic
        .to(
          sectionCanvasWrapper,
          {
            duration: 0.5,
            ease: "power2.in",

            // scales down canvas to match height of svg
            scale() {
              const canvasHeight = sectionCanvas.getBoundingClientRect().height;
              const displayHeight = display.getBoundingClientRect().height;
              return displayHeight / canvasHeight;
            },

            // decreases width of wrapper to crop canvas and effectively invert orientation
            width: function portraitRatio() {
              const { height } = sectionCanvas.getBoundingClientRect();
              return height * (9 / 16);
            },
          },
          ">-1.5"
        )

        // fade out image sequence
        .to(sectionCanvasWrapper, { opacity: 0, duration: 0.25 }, "<0.5")

        // fade main headline in and out
        .add(textTween(largeHeader), "<")

        // dim out image sequence canvas
        .to(phoneWrapper, { opacity: 0.2, duration: 0.5 }, ">-1.5")

        // section to takeaway transition image sequence
        .add(
          getSequenceTween({
            canvasRef: takeawayCanvasRef,
            duration: newsletterTransition.frameCount / 20,
            frameOptions: {
              srcPrefix: newsletterTransition.srcPrefix,
              frameCount: newsletterTransition.frameCount,
              width: newsletterTransition.width,
              height: newsletterTransition.height,
            },
          }),
          ">0.25"
        )
    );
  };

  const getTakeawayTimeline = () => {
    const takeawaysTimeline = gsap.timeline();

    // key takeaways image sequences and headlines
    takeaways.forEach((takeaway, index) => {
      const { srcPrefix, frameCount, width, height } = takeaway;
      const takeawayTitleEl = takeawayRefs[index].current;

      takeawaysTimeline
        // image sequence
        .add(
          getSequenceTween({
            canvasRef: takeawayCanvasRef,
            duration: 0.5,
            frameOptions: {
              srcPrefix,
              frameCount,
              width,
              height,
            },
          })
        )

        // fade in title
        .add(textTween(takeawayTitleEl), ">-0.25");
    });

    return takeawaysTimeline;
  };

  const getNewsletterTimeline = () => {
    const newsletterTitle = newsletterTitleRef.current;
    const newsletterWrapper = newsletterWrapperRef.current;
    const newsletterMask = newsletterMaskRef.current;
    const phoneWrapper = phoneWrapperRef.current;
    const timeline = gsap
      .timeline()

      // scale newsletter image mask to fit "svg phone display"
      .set(newsletterMask, {
        width: () => {
          const svgDisplay = svgAltRefs.displayRef.current;
          return svgDisplay.getBoundingClientRect().width;
        },
      })

      // slide in first newsletter color
      .to(
        initialColorSlideRef.current,
        { xPercent: -100, duration: 0.5, ease: "power2.inOut" },
        0
      )

      // fade out takeaway phone while shifting back to center
      .to(
        phoneWrapper,
        { opacity: 0, x: "0%", duration: 0.5, ease: "power2.out" },
        0
      )

      // fade in title
      .add(textTween(newsletterTitle), 0)

      // fade in and shift newsletters to center
      .fromTo(
        newsletterWrapper,
        { opacity: 0, x: 0 },
        { opacity: 1.0, x: "-50%", duration: 0.5, ease: "power2.out" },
        ">"
      );

    // slide between newsletters
    newsletters.forEach((_, index) => {
      const coloredBg = newsletterColorSlideRef[index].current;
      const phoneNewsletterImg = newsletterImageRefs[index].current;

      if (index < newsletters.length - 1) {
        timeline

          // add a "pause"
          .to({}, { duration: 0.25 })

          // shift background color
          .to(coloredBg, {
            xPercent: -100,
            duration: 0.25,
            ease: "power2.inOut",
          })

          // shift phone image
          .to(
            phoneNewsletterImg,
            { xPercent: -100, duration: 0.25, ease: "power2.inOut" },
            "<"
          );
      }
    });

    return timeline;
  };

  const buildAnimationTimeline = () => {
    const sectionsWrapper = sectionsWrapperRef.current;
    const scrollTriggerId = "showcaseAnimation";
    const introductionTimeline = getIntroductionTimeline();
    const sectionTransitionTimeline = getSectionTransitionTimeline();
    const takeawayTimeline = getTakeawayTimeline();
    const newsletterTimeline = getNewsletterTimeline();
    const masterTimeline = gsap
      .timeline()
      .add(introductionTimeline)
      .add(sectionTransitionTimeline)
      .add(takeawayTimeline)
      .add(newsletterTimeline);

    return {
      timeline: masterTimeline,
      scrollTriggerInstance: ScrollTrigger.create({
        id: scrollTriggerId,
        animation: masterTimeline,
        trigger: sectionsWrapper,
        start: "bottom bottom",
        end: `${1800 * masterTimeline.totalDuration()}`,
        invalidateOnRefresh: true,
        scrub: true,
        pin: true,
      }),
    };
  };

  useEffect(() => {
    const { timeline, scrollTriggerInstance } = buildAnimationTimeline();

    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <Section colorName="charcoal" ref={showcaseSectionRef}>
      <ColorSlides>
        {newsletters.map(({ colorVal = Colors.charcoal, src }, index) => (
          <Slide
            ref={newsletterColorSlideRef[index]}
            key={`color-slide-${src.publicURL}`}
            zIndex={newsletters.length - index}
            colorVal={colorVal}
          />
        ))}

        <Slide
          ref={initialColorSlideRef}
          colorVal={Colors.charcoal}
          zIndex={newsletters.length + 1}
        />
      </ColorSlides>

      <ShowcaseWrapper>
        <PhoneWrapper ref={phoneWrapperRef}>
          <StyledPhoneSVG ref={svgRefs} />
          <TakeawayCanvas ref={takeawayCanvasRef} />
        </PhoneWrapper>

        {takeawaysData.map((textData, index) => (
          <CopyBlock ref={takeawayRefs[index]} key={textData.title}>
            <div>
              <BlockTitle>{textData.title}</BlockTitle>
              {textData.description && (
                <BlockDescription>{textData.description}</BlockDescription>
              )}
            </div>
          </CopyBlock>
        ))}

        <TitleBlock ref={newsletterTitleRef}>
          <BlockTitle>{data.newsletterTitle}</BlockTitle>
        </TitleBlock>

        <NewslettersWrapper ref={newsletterWrapperRef}>
          <StyledPhoneAltSVG ref={svgAltRefs} />

          <NewslettersMask ref={newsletterMaskRef}>
            {newsletters.map(({ src }, index) => {
              return (
                <NewsletterImage
                  ref={newsletterImageRefs[index]}
                  src={src.publicURL}
                  key={src.publicURL}
                  zIndex={newsletters.length - index}
                />
              );
            })}
          </NewslettersMask>
        </NewslettersWrapper>
      </ShowcaseWrapper>

      <SectionCanvasWrapper
        ref={sectionCanvasWrapperRef}
        srcWidth={sectionTransition.width}
        srcHeight={sectionTransition.height}
      >
        <SectionCanvas
          ref={sectionCanvasRef}
          srcWidth={sectionTransition.width}
          srcHeight={sectionTransition.height}
        />
      </SectionCanvasWrapper>

      <LargeHeader
        ref={largeHeaderRef}
        dangerouslySetInnerHTML={{
          __html: convertMarkdownToHtml(data.headline),
        }}
      />
    </Section>
  );
});

export default MobileShowcase;
