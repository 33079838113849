import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import {
  SectionsWrapper,
  StyledCanvas,
} from "./introduction-showcase.styled.js";
import Introduction from "./introduction";
import Showcase from "./showcase";

gsap.registerPlugin(ScrollTrigger);

const imageSequences = {
  introduction: {
    logoSequence: {
      srcPrefix: "/introduction/introduction_",
      frameCount: 53,
      width: 1280,
      height: 720,
    },

    newsletterSequence: {
      srcPrefix: "/introduction-newsletter/introduction-newsletter_",
      frameCount: 19,
      width: 1280,
      height: 720,
    },
  },

  showcase: {
    sectionTransition: {
      srcPrefix: "/plunge/plunge_",
      frameCount: 20,
      width: 1280,
      height: 720,
    },

    newsletterTransition: {
      srcPrefix: "/hint/hint_",
      frameCount: 30,
      width: 480,
      height: 854,
    },

    takeaways: [
      {
        frameCount: 20,
        srcPrefix: "/inbox/inbox_",
        width: 480,
        height: 854,
      },
      {
        frameCount: 19,
        srcPrefix: "/uber/uber_",
        width: 480,
        height: 854,
      },
      {
        frameCount: 19,
        srcPrefix: "/convert/convert_",
        width: 480,
        height: 854,
      },
    ],
  },
};

const IntroductionShowcase = ({ data }) => {
  const { width, height } = imageSequences.introduction.logoSequence;
  const canvasRef = useRef(null);
  const sectionsWrapperRef = useRef(null);
  const introductionSectionRef = useRef(null);
  const showcaseSectionRef = useRef(null);
  const introductionHeadlineRef = useRef(null);
  const introductionRefs = {
    canvasRef,
    introductionHeadlineRef,
    introductionSectionRef,
  };
  const showcaseRefs = {
    sectionsWrapperRef,
    introductionHeadlineRef,
    introductionSectionRef,
    showcaseSectionRef,
  };

  const arrangeSections = () => {
    gsap.set([introductionSectionRef.current, showcaseSectionRef.current], {
      zIndex: (index, _, targets) => targets.length - index,
    });
  };

  useEffect(() => {
    arrangeSections();
  });

  return (
    <>
      <StyledCanvas ref={canvasRef} srcWidth={width} srcHeight={height} />
      <SectionsWrapper ref={sectionsWrapperRef}>
        <Showcase
          data={data.showcase}
          imageSequences={imageSequences.showcase}
          ref={showcaseRefs}
        />
        <Introduction
          data={data.introduction}
          imageSequences={imageSequences.introduction}
          ref={introductionRefs}
        />
      </SectionsWrapper>
    </>
  );
};

export default IntroductionShowcase;
