import styled from "styled-components";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ChartWrapper = styled.div`
  text-align: center;
`;

const CanvasWrapper = styled.div`
  position: relative;
  margin: 32px auto;
  width: ${({ canvasWidth }) => canvasWidth};
  height: ${({ chartData }) => chartData.length * 60}px;
`;

const Header = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-weight: bold;
  color: ${Colors.white};
  font-size: 24px;
  line-height: 1.3;
  margin: 0;

  @media (max-width: ${Screens.lg}) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const Subheading = styled.p`
  font-family: ${Fonts.ProximaNovaFont};
  color: ${Colors.white};
  font-size: 16px;
  line-height: 1.4;
  margin-top: 16px;
`;

const Description = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-top: 32px;
  border-top: 1px solid ${Colors.grayDarker};
  color: ${Colors.white};
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${Fonts.ProximaNovaFont};
`;

const Above = styled.span`
  color: ${({ color }) => color};
`;

export {
  Canvas,
  ChartWrapper,
  CanvasWrapper,
  Header,
  Description,
  Above,
  Subheading,
};
