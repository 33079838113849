import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import {
  LargeTitle,
  SmallTitle,
  StyledScrollIndicatorSVG,
  IndicatorWrapper,
  VerticalRule,
} from "./scroll-indicator.styled.js";

gsap.registerPlugin(ScrollTrigger);

const ScrollIndicator = ({ scrollIndicatorText }) => {
  const indicatorWrapperRef = useRef(null);

  // hide indicator if body's scroll position is => 1px
  const applyScrollTrigger = () => {
    const indicatorWrapper = indicatorWrapperRef.current;
    const hideIndicator = gsap.to(indicatorWrapper, {
      y: -20,
      opacity: 0,
      duration: 0.25,
      onComplete: () => gsap.set(indicatorWrapper, { display: "none" }),
    });

    return ScrollTrigger.create({
      animation: hideIndicator,
      trigger: "body",
      start: "top top-=1",
      end: "top top-=1",
      onEnterBack: () => {
        hideIndicator.reverse();
        gsap.set(indicatorWrapper, { display: "block" });
      },
    });
  };

  useEffect(() => {
    const scrollTriggerInstance = applyScrollTrigger();
    return () => scrollTriggerInstance.kill(true);
  });

  return (
    <IndicatorWrapper ref={indicatorWrapperRef}>
      <LargeTitle>{scrollIndicatorText}</LargeTitle>
      <VerticalRule />
      <StyledScrollIndicatorSVG />
      <SmallTitle>scroll down</SmallTitle>
    </IndicatorWrapper>
  );
};

export default ScrollIndicator;
