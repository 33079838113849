import React from "react";
import * as Colors from "@constants/colors";

const PhoneSVG = React.forwardRef(
  ({ desktopImage, color = Colors.gray, className }, ref) => {
    const { svgRef, displayRef } = ref;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 320 560.32"
        fill="none"
        ref={svgRef}
      >
        <path
          stroke={color}
          fill={Colors.charcoal}
          vectorEffect="non-scaling-stroke"
          d="M30.5,5.98h259c11.05,0,20,8.95,20,20v488.05c0,11.05-8.95,20-20,20h-259c-11.05,0-20-8.95-20-20V25.98
                C10.5,14.93,19.45,5.98,30.5,5.98z"
        />
        <path
          stroke={color}
          fill={Colors.charcoal}
          vectorEffect="non-scaling-stroke"
          d="M10.5,153.98L10.5,153.98c-2.48,0-4.5-2.01-4.5-4.5v-39c0-2.48,2.01-4.5,4.5-4.5h0V153.98z"
        />
        <path
          stroke={color}
          fill={Colors.charcoal}
          vectorEffect="non-scaling-stroke"
          d="M10.5,216.98L10.5,216.98c-2.48,0-4.5-2.01-4.5-4.5v-39c0-2.48,2.01-4.5,4.5-4.5h0V216.98z"
        />
        <path
          stroke={color}
          fill={Colors.charcoal}
          vectorEffect="non-scaling-stroke"
          d="M309.5,216.98L309.5,216.98l0-111h0c2.48,0,4.5,2.01,4.5,4.5v102C314,214.96,311.98,216.98,309.5,216.98z"
        />
        <path
          stroke={color}
          vectorEffect="non-scaling-stroke"
          d="M198.45,21.04L198.45,21.04c1.52,0,2.75,1.23,2.75,2.75l0,0c0,1.52-1.23,2.75-2.75,2.75l0,0
                c-1.52,0-2.75-1.23-2.75-2.75l0,0C195.7,22.27,196.93,21.04,198.45,21.04z"
        />
        <path
          stroke={color}
          d="M122.55,21.04h62.7c1.52,0,2.75,1.23,2.75,2.75l0,0c0,1.52-1.23,2.75-2.75,2.75h-62.7
                c-1.52,0-2.75-1.23-2.75-2.75l0,0C119.8,22.27,121.03,21.04,122.55,21.04z"
        />
        {!desktopImage && (
          <rect
            ref={displayRef}
            x="25.82"
            y="41.61"
            width="268.37"
            height="477.1"
          />
        )}
        {desktopImage && (
          <g ref={displayRef}>
            <defs>
              <path
                id="phone-display"
                d="M31.82,41.61h256.37c3.31,0,6,2.69,6,6v465.1c0,3.31-2.69,6-6,6H31.82c-3.31,0-6-2.69-6-6V47.61
                                C25.82,44.3,28.51,41.61,31.82,41.61z"
              />
            </defs>
            <clipPath id="phone-screen">
              <use
                href="#phone-display"
                style={{ overflow: "overflow:visible" }}
              />
            </clipPath>
            <g
              transform="matrix(1 0 0 1 0 0)"
              style={{ clipPath: "url(#phone-screen)" }}
            >
              <image
                style={{ overflow: "overflow:visible" }}
                width="720"
                height="1280"
                href={desktopImage}
                transform="matrix(0.3727 0 0 0.3727 25.82 41.61)"
              />
            </g>
          </g>
        )}
      </svg>
    );
  }
);

export default PhoneSVG;
