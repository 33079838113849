import styled, { keyframes } from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { ClearfaceFont, ProximaNovaFont } from "@constants/fonts";
import ScrollIndicatorSVG from "@svg-icons/scroll-indicator";

const notifyAnimation = keyframes`
    0% { transform: translateY(-16px); }
    100% { transform: translateY(16px); }
`;

const IndicatorWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${Colors.white};
  z-index: 101;
  pointer-events: none;
  text-align: center;
`;

const LargeTitle = styled.p`
  font-size: 28px;
  font-family: ${ClearfaceFont};
  margin: 0;
`;

const SmallTitle = styled.p`
  font-size: 16px;
  color: ${Colors.gray};
  font-family: ${ProximaNovaFont};
  margin: 32px 0 0 0;
`;

const StyledScrollIndicatorSVG = styled(ScrollIndicatorSVG)`
  display: block;
  width: 68px;
  margin: 0 auto;
  animation-name: ${notifyAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-direction: alternate;
`;

const VerticalRule = styled.div`
  display: block;
  width: 1px;
  height: 128px;
  background-color: ${Colors.white};
  margin: 32px auto;

  @media (max-width: ${Screens.sm}) {
    height: 80px;
  }
`;

export {
  LargeTitle,
  SmallTitle,
  IndicatorWrapper,
  StyledScrollIndicatorSVG,
  VerticalRule,
};
