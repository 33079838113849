import React from "react";
import * as Colors from "@constants/colors";

const TabletLandscapeSVG = React.forwardRef(
  ({ color = Colors.gray, className }, ref) => {
    const { svgRef, displayRef } = ref;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 965.84 566"
        fill="none"
        ref={svgRef}
      >
        <path
          fill={Colors.charcoal}
          d="M923.6,5.69H201.8v-0.6c0-2.21-1.79-4-4-4h-40.57c-2.21,0-4,1.79-4,4v0.6h-9.15v-0.6c0-2.21-1.79-4-4-4H99.51
                c-2.21,0-4,1.79-4,4v0.6H45.19c-20.99,0-38,17.01-38,38v21.37H6c-2.21,0-4,1.79-4,4v53.83c0,2.21,1.79,4,4,4h1.19v395.42
                c0,20.99,17.01,38,38,38H923.6c20.99,0,38-17.01,38-38V43.69C961.6,22.7,944.59,5.69,923.6,5.69z"
        />
        <rect ref={displayRef} x="39.95" y="33" width="888.89" height="500" />
      </svg>
    );
  }
);

export default TabletLandscapeSVG;
